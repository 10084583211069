import { Link as RemixLink, useLocation } from "@remix-run/react";
import type { RemixLinkProps } from "@remix-run/react/dist/components";
import {
    ArrowRight,
    Bot,
    ChevronDown,
    ClipboardCheck,
    FileEdit,
    FileText,
    LayoutTemplate,
    Menu,
    MessageSquare,
    X,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";

function Link({ children, to, ...props }: RemixLinkProps) {
    return (
        <RemixLink
            to={to}
            {...props}
            className={cn(
                "font-medium text-sm transition-colors duration-200 hover:text-purple-600",
                props.className,
            )}
        >
            {children}
        </RemixLink>
    );
}

interface FeatureItem {
    icon: React.ReactNode;
    title: string;
    description: string;
    href: string;
}

function DropdownMenu({
    isOpen,
    items,
    secondColumnItems,
    title,
}: {
    isOpen: boolean;
    items: FeatureItem[];
    secondColumnItems: FeatureItem[];
    title: string;
}) {
    if (!isOpen) return null;

    return (
        <div className="absolute top-full inset-x-0 mt-0 bg-white shadow-lg ring-1 ring-gray-200 focus:outline-none z-50">
            <div className="container mx-auto py-6 px-6 lg:px-8">
                <div className="mb-4 font-semibold text-lg text-purple-700">{title}</div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Left column - First set of feature items */}
                    <div className="space-y-4">
                        {items.map((item) => (
                            <Link
                                key={item.href}
                                to={item.href}
                                className="flex items-start gap-4 rounded-md p-3 text-gray-700 transition-all hover:bg-purple-50 hover:text-purple-700"
                            >
                                <div className="mt-0.5 flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-purple-100 text-purple-600">
                                    {item.icon}
                                </div>
                                <div>
                                    <p className="font-medium">{item.title}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                            </Link>
                        ))}
                    </div>

                    {/* Middle column - Second set of feature items */}
                    <div className="space-y-4">
                        {secondColumnItems.map((item) => (
                            <Link
                                key={item.href}
                                to={item.href}
                                className="flex items-start gap-4 rounded-md p-3 text-gray-700 transition-all hover:bg-purple-50 hover:text-purple-700"
                            >
                                <div className="mt-0.5 flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-purple-100 text-purple-600">
                                    {item.icon}
                                </div>
                                <div>
                                    <p className="font-medium">{item.title}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                            </Link>
                        ))}
                    </div>

                    {/* Right column - CTA section */}
                    <div className="bg-gradient-to-br from-purple-50 to-blue-50 rounded-lg p-6 flex flex-col justify-between">
                        <div>
                            <h3 className="text-xl font-bold text-purple-900 mb-2">
                                Create your tailored CV today
                            </h3>
                            <p className="text-gray-700 mb-6">
                                Join thousands of professionals who have boosted their job search
                                success with our AI-powered tools.
                            </p>
                            <Button
                                className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 transition-colors duration-200 hover:from-pink-700 hover:via-purple-800 hover:to-blue-800 w-full sm:w-auto"
                                asChild
                            >
                                <Link
                                    to="/auth/signup"
                                    className="flex items-center justify-center gap-2"
                                >
                                    Sign Up
                                    <ArrowRight size={16} />
                                </Link>
                            </Button>
                        </div>
                        <p className="text-sm text-gray-500 mt-4">
                            Get started with our Pro Plan today.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
    const headerRef = useRef<HTMLElement>(null);
    const dropdownButtonRef = useRef<HTMLButtonElement>(null);
    const location = useLocation();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setIsMenuOpen(false);
        setActiveDropdown(null);
    }, [location]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const target = event.target as Node;

            if (dropdownButtonRef.current?.contains(target)) {
                return;
            }

            if (headerRef.current && !headerRef.current.contains(target)) {
                setActiveDropdown(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function toggleDropdown(name: string) {
        setActiveDropdown((prev) => (prev === name ? null : name));
    }

    const featureItems: FeatureItem[] = [
        {
            icon: <FileText size={20} />,
            title: "Resume Tailoring",
            description: "Customize your resume for specific job applications",
            href: "/#features",
        },
        {
            icon: <FileEdit size={20} />,
            title: "AI Cover Letter Generator",
            description: "Create personalized cover letters in seconds with AI",
            href: "/ai-cover-letter-generator",
        },
        {
            icon: <MessageSquare size={20} />,
            title: "AI Interview",
            description: "Practice interviews with our AI-powered assistant",
            href: "/ai-interview",
        },
    ];

    const secondColumnFeatureItems: FeatureItem[] = [
        {
            icon: <Bot size={20} />,
            title: "AI Resume Builder",
            description: "Build a professional resume with our AI assistant",
            href: "/ai-resume-builder",
        },
        {
            icon: <ClipboardCheck size={20} />,
            title: "Resume Checker",
            description: "Get your resume scored and improve your chances",
            href: "/resume-checker",
        },
        {
            icon: <LayoutTemplate size={20} />,
            title: "Resume Templates",
            description: "Browse our collection of ATS-friendly templates",
            href: "/top-resume-templates",
        },
    ];

    // Combine all feature items for mobile menu
    const allFeatureItems = [...featureItems, ...secondColumnFeatureItems];

    return (
        <header
            ref={headerRef}
            className="sticky top-0 z-50 flex h-16 items-center justify-between bg-white px-6 shadow-sm backdrop-blur md:supports-[backdrop-filter]:bg-white/60 lg:px-8"
        >
            <Link
                className="flex items-center justify-center"
                to="/"
                onClick={() => setIsMenuOpen(false)}
            >
                <img src="/logo.svg" alt="Logo" className="h-7" />
            </Link>
            <nav className="hidden items-center gap-6 sm:gap-8 md:flex">
                <div className="relative">
                    <button
                        ref={dropdownButtonRef}
                        className="flex items-center gap-1.5 font-medium text-sm transition-colors duration-200 hover:text-purple-600"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown("features");
                        }}
                        aria-expanded={activeDropdown === "features"}
                        aria-haspopup="true"
                    >
                        Features
                        <ChevronDown
                            size={14}
                            className={cn(
                                "transition-transform duration-200",
                                activeDropdown === "features" ? "rotate-180" : "",
                            )}
                        />
                    </button>
                </div>
                <Link to="/#how-it-works">How it works</Link>
                <Link to="/#pricing">Pricing</Link>
                <Link to="/knowledgebase">Knowledgebase</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/contact">Contact</Link>
                <Button
                    variant="outline"
                    className="transition-colors duration-200 hover:bg-purple-50"
                    asChild
                >
                    <Link to="/auth/login">Log in</Link>
                </Button>
                <Button
                    className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 transition-colors duration-200 hover:from-pink-700 hover:via-purple-800 hover:to-blue-800"
                    asChild
                >
                    <Link to="/auth/signup">Sign up</Link>
                </Button>
            </nav>
            <button
                className="text-gray-600 transition-colors duration-200 hover:text-purple-600 md:hidden"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            {/* Full-width dropdown for Features */}
            <DropdownMenu
                isOpen={activeDropdown === "features"}
                items={featureItems}
                secondColumnItems={secondColumnFeatureItems}
                title="Features"
            />

            {isMenuOpen && (
                <div className="absolute top-16 right-0 left-0 bg-white p-4 shadow-md backdrop-blur md:hidden md:supports-[backdrop-filter]:bg-white/60">
                    <nav className="flex flex-col gap-4">
                        <div className="flex flex-col">
                            <button
                                className="flex items-center justify-between font-medium text-sm py-2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleDropdown("mobileFeatures");
                                }}
                                aria-expanded={activeDropdown === "mobileFeatures"}
                            >
                                Features
                                <ChevronDown
                                    size={14}
                                    className={cn(
                                        "transition-transform duration-200",
                                        activeDropdown === "mobileFeatures" ? "rotate-180" : "",
                                    )}
                                />
                            </button>
                            {activeDropdown === "mobileFeatures" && (
                                <div className="mt-2 space-y-3 rounded-md bg-gray-50 p-3">
                                    {allFeatureItems.map((item) => (
                                        <Link
                                            key={item.href}
                                            onClick={() => setIsMenuOpen(false)}
                                            to={item.href}
                                            className="flex items-start gap-3 rounded-md p-2 text-gray-700 transition-all hover:bg-purple-50 hover:text-purple-700"
                                        >
                                            <div className="mt-0.5 flex h-8 w-8 shrink-0 items-center justify-center rounded-md bg-purple-100 text-purple-600">
                                                {item.icon}
                                            </div>
                                            <div>
                                                <p className="font-medium">{item.title}</p>
                                                <p className="mt-1 text-xs text-gray-500">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                        <Link onClick={() => setIsMenuOpen(false)} to="/#how-it-works">
                            How it works
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/#pricing">
                            Pricing
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/knowledgebase">
                            Knowledgebase
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/blog">
                            Blog
                        </Link>
                        <Link onClick={() => setIsMenuOpen(false)} to="/contact">
                            Contact
                        </Link>
                        <Button
                            variant="outline"
                            className="w-full transition-colors duration-200 hover:bg-purple-50"
                            asChild
                        >
                            <Link to="/auth/login">Log in</Link>
                        </Button>
                        <Button
                            className="w-full bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 transition-colors duration-200 hover:from-pink-700 hover:via-purple-800 hover:to-blue-800"
                            asChild
                        >
                            <Link to="/auth/signup">Sign up</Link>
                        </Button>
                    </nav>
                </div>
            )}
        </header>
    );
}
